const LINK_TOS = 'https://www.anwalt.de/pdf/anwalt.de_bewertungsrichtlinien.pdf';

const SOCIAL_MEDIAS_SHARE_LINKS: Record<string, string> = {
  FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
  TWITTER: 'https://twitter.com/intent/tweet?url=',
  LINKEDIN: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  XING: 'https://www.xing.com/spi/shares/new?url=',
  WHATSAPP: 'https://api.whatsapp.com/send?text=',
  EMAIL: 'mailto:?',
};

const ARTICLES_LINK = '/rechtstipps.php';
const PRODUCTS_LINK = '/rechtsprodukte.php';
const DIRECT_MESSAGE = '/nachricht-verfassen?source=2';
const RATE_LINK = '/bewertung/schreiben?source=4';
const CONTACT = '/kontakt';

export {
  LINK_TOS,
  SOCIAL_MEDIAS_SHARE_LINKS,
  ARTICLES_LINK,
  DIRECT_MESSAGE,
  PRODUCTS_LINK,
  RATE_LINK,
  CONTACT,
};
