<script setup lang="ts">
import { nextTick, onMounted, ref, computed } from 'vue';
import { AnwReadMoreFaded } from '@anwalt.de/tailwind-design-system';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import SectionHeadline from "@/shared/components/SectionHeadline.vue";

const { imprint } = storeToRefs(useUserStore());
const { t } = useI18n();
const collapsedText = ref(false);

const imprintLength = computed(() => {
  return imprint.value.replace(/<\/?[^>]+(>|$)/g, '').length;
});

onMounted(async () => {
  await nextTick();
  collapsedText.value = true;
});
</script>
<template>
  <section v-if="imprint" class="anw-profile-card lg:rounded-xl p-4" data-test-id="imprint">
    <SectionHeadline :headline="t('companyImprint')" />
    <div v-if="imprintLength < 140" v-html="imprint" class="anw-wysiwyg mt-4"></div>
    <div v-else class="mt-4">
      <div v-if="!collapsedText" v-html="imprint" class="anw-wysiwyg"></div>
      <AnwReadMoreFaded v-else :text="imprint" />
    </div>
  </section>
</template>
