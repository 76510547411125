<script setup lang="ts">
import { AnwReadMoreFaded } from '@anwalt.de/tailwind-design-system';
import { onMounted, nextTick, ref } from 'vue';
import { useSeoLink } from '../../composables/useSeoLink';

const { aboutMeText } = useSeoLink();
const collapsedText = ref(false);

onMounted(async () => {
  await nextTick();
  collapsedText.value = true;
});
</script>

<template>
  <div>
    <div v-if="!collapsedText" v-html="aboutMeText" class="anw-wysiwyg"></div>
    <AnwReadMoreFaded v-else :text="aboutMeText" />
  </div>
</template>
