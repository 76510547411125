<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Ref, onMounted, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLocationDot, faClock } from '@fortawesome/pro-light-svg-icons';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import Map from './Map/Map.vue';
import ContactList from './ContactList.vue';
import BarrierFree from '../Badges/BarrierFree.vue';
import { useContactDetailsStore } from '@/shared/stores/ContactDetailStore';
import { ContactHelper } from '@/shared/helper/ContactHelper';
import { AddressHelper } from '@/modules/profile-page/helpers/AddressHelper';
import { OpeningHours } from '@/shared/helper/OpeningHoursHelper';
import { storeToRefs } from 'pinia';
import { ModifiedContactDetail } from '@shared/types/ContactDetail';

const { t } = useI18n();

const { modifiedContactDetails } = storeToRefs(useContactDetailsStore());
const { formattedAddress } = AddressHelper();
const { phoneNumbers, otherContacts } = ContactHelper();
const companyOpenings: Ref = ref({});
const addressList: Ref = ref(null);
const isShown = ref(new Array(modifiedContactDetails.value.length).fill(false));

onMounted(() => {
  modifiedContactDetails.value.map((contact) => {
    companyOpenings.value[contact.id] = new OpeningHours(contact.workingTime).getOpeningHours();
  });
});

const onToggleContact = (idx: number) => {
  isShown.value[idx] = !isShown.value[idx];
};
</script>

<template>
  <ul ref="addressList" class="grid gap-4 mt-4">
    <li v-for="(contact, idx) in modifiedContactDetails" :key="contact.id" :id="String(contact.id)" class="">
      <div class="bg-neutral-50 p-4 rounded-xl grid gap-4 md:gap-8 md:grid-cols-2 items-start">
        <div class="grid gap-1 md:order-2 md:col-start-1">
          <p class="font-semibold">{{ t('contactAddress') }}</p>
          <div class="flex gap-3">
            <span class="flex justify-center w-5 shrink-0">
              <FontAwesomeIcon :icon="faLocationDot" class="fa-1x pt-0.5" aria-hidden="true" />
            </span>
            <div>
              <p v-if="contact.companyName">
                {{ contact.companyName }}
              </p>
              <p v-html="formattedAddress(contact.address, true)"></p>
              <p>
                {{
                  t('distance', {
                    km: Intl.NumberFormat('de-DE').format((contact as ModifiedContactDetail).calculatedDistance),
                  })
                }}
              </p>
              <BarrierFree :is-barrier-free="contact.isBarrierFree" />
            </div>
          </div>
        </div>
        <div>
          <Map :mapInformation="contact" class="rounded-xl overflow-hidden" />
        </div>
        <div :class="{ hidden: !isShown[idx], grid: isShown[idx] }" class="gap-3 md:!grid md:row-span-2">
          <div v-if="companyOpenings[contact.id]" class="grid gap-1">
            <p class="font-semibold">{{ t('contactOpeningHours') }}</p>
            <div class="flex gap-3">
              <span class="flex justify-center w-5 shrink-0">
                <FontAwesomeIcon :icon="faClock" class="fa-1x mt-0.5" aria-hidden="true" />
              </span>
              <div class="grid gap-1">
                <div class="flex flex-col gap-y-2">
                  <span
                    v-for="(week, hours, idx) in companyOpenings[contact.id]"
                    :key="idx"
                    class="flex gap-6 justify-between">
                    <div v-html="week" class="min-w-fit"></div>
                    <div v-html="hours"></div>
                  </span>
                </div>
                <p v-if="contact.workingTimeNote" class="text-sm text-neutral-600" v-html="contact.workingTimeNote"></p>
              </div>
            </div>
          </div>

          <div class="grid gap-1">
            <p class="font-semibold">{{ t('contact') }}</p>
            <div class="grid gap-3">
              <ContactList :contacts="phoneNumbers(idx)" :contact-id="contact.id" />
              <ContactList :contacts="otherContacts(idx)" :contact-id="contact.id" />
            </div>
          </div>
        </div>

        <button
          class="font-semibold text-info-500 flex items-center justify-center gap-1 md:hidden"
          @click="() => onToggleContact(idx)">
          {{ !isShown[idx] ? t('contactShowMore') : t('contactShowLess') }}
          <FontAwesomeIcon v-if="!isShown[idx]" :icon="faAngleDown" class="fa-1x" aria-hidden="true" fixed-width />
          <FontAwesomeIcon v-else :icon="faAngleUp" class="fa-1x" aria-hidden="true" fixed-width />
        </button>
      </div>
    </li>
  </ul>
</template>
