<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SectionHeadline from '@/shared/components/SectionHeadline.vue';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { useBaseLawyerStore } from '@/shared/stores/BaseLawyerStore';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import { RATE_LINK } from '@/modules/profile-page/constants/Links';
import { RATING_LINK } from '@shared/constants/Links';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { NumberFormatter } from '@/shared/helper/NumberFormatter';
import RatingList from '../RatingList/RatingList.vue';

const { t } = useI18n();
const { rating } = useRatingStore();
const { profile } = useBaseLawyerStore();
const { profileProductId } = useProfileProductId();
const { uri, displayedName } = useUserStore();
const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();
const { dataAttributesGA, updateAttributeGA } = useGoogleAnalytics();

const getRateButtonTitle = computed(() => {
  return (
    getTranslationByProductId('rateButtonTitle', profileProductId, { n: getGenderValueForTranslation(profile.sex) }) +
    ' ' +
    displayedName
  );
});

const getButtonTitle = computed(() =>
  getTranslationByProductId('rateButton', profileProductId, { n: getGenderValueForTranslation(profile.sex) })
);

updateAttributeGA({
  [GA_ATRIBUTES.LABEL]: t('eventLabelProfile'),
});
</script>

<template>
  <section class="anw-profile-card lg:rounded-xl" v-if="rating.isRateable" :id="NAVBAR_ITEMS.REVIEW">
    <div>
      <SectionHeadline :headline="t('reviewsHeading')" :counter="rating.ratingCount" />
      <RatingList class="pt-4" />
      <div class="md:flex flex-row-reverse justify-between items-center">
        <a
          v-bind="{
            ...dataAttributesGA,
            [GA_ATRIBUTES.ACTION]: t('eventActionClickRatingButton', { name: getButtonTitle }),
          }"
          class="anw-btn anw-btn-primary-outline hover:!no-underline mt-6 md:w-[272px]"
          :title="getRateButtonTitle"
          :href="uri + RATE_LINK">
          {{ getButtonTitle }}
        </a>
        <div v-if="rating.ratingCount" class="flex justify-center sm:justify-start mt-6">
          <a
            class="font-semibold text-info-500"
            :href="uri + RATING_LINK"
            :title="t('showAllRatingsTitle', { name: displayedName })"
            v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickRatingReadAll') }"
            >{{ t('showAllRatings', { count: NumberFormatter().formatCount(rating.ratingCount) }) }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>
