<script setup lang="ts">
import { useArticleStore } from '@/shared/stores/ArticleStore';
import ArticleList from '@/shared/components/Article/ArticleList.vue';
import { storeToRefs } from 'pinia';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';

const { articleList } = useArticleStore();
const { profileProductId } = storeToRefs(useProfileProductId());
</script>
<template>
  <ArticleList :list-items="articleList" :profile-product-id="profileProductId" class="mt-8" custom-class="mb-4" />
</template>
