<script setup lang="ts">
import ReviewItem from '@/shared/components/Review/ReviewItem.vue';
import { useRatingStore } from '@/modules/profile-page/stores/RatingStore';
import { storeToRefs } from 'pinia';

const { reviewList } = storeToRefs(useRatingStore());
</script>

<template>
  <div>
    <ReviewItem
      class="mb-2 last-of-type:mb-0"
      v-for="(review, index) in reviewList"
      :key="review.id"
      :review="review"
      :index="index" />
  </div>
</template>
